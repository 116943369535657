import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Admin Interface`}</h1>
    <ul>
      <li parentName="ul">{`You can access the admin interface at `}<inlineCode parentName="li">{`pol.is/`}</inlineCode>{` after you've logged in.`}</li>
      <li parentName="ul">{`The admin dashboard is responsible for the following views:`}
        <ul parentName="li">
          <li parentName="ul">{`Listing all `}<a parentName="li" {...{
              "href": "/conversations",
              "title": "conversations"
            }}>{`conversations`}</a>{` attached to your account`}</li>
          <li parentName="ul">{`Starting a new `}<a parentName="li" {...{
              "href": "/conversation",
              "title": "conversation"
            }}>{`conversation`}</a>{``}</li>
          <li parentName="ul">{`Signing out`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Once you've started a `}<a parentName="li" {...{
          "href": "/conversation",
          "title": "conversation"
        }}>{`conversation`}</a>{`, it also allows you to:`}
        <ul parentName="li">
          <li parentName="ul">{`Change `}<a parentName="li" {...{
              "href": "/conversation-configuration",
              "title": "conversation configuration"
            }}>{`conversation configuration`}</a>{``}</li>
          <li parentName="ul">{`Get a `}<a parentName="li" {...{
              "href": "/url",
              "title": "url"
            }}>{`url`}</a>{` to the `}<a parentName="li" {...{
              "href": "/conversation",
              "title": "conversation"
            }}>{`conversation`}</a>{` in `}<a parentName="li" {...{
              "href": "/distribute",
              "title": "distribute"
            }}>{`distribute`}</a>{``}</li>
          <li parentName="ul">{`Get an `}<a parentName="li" {...{
              "href": "/iframe",
              "title": "iframe"
            }}>{`iframe`}</a>{` `}<a parentName="li" {...{
              "href": "/embed-code",
              "title": "embed code"
            }}>{`embed code`}</a>{` in `}<a parentName="li" {...{
              "href": "/distribute",
              "title": "distribute"
            }}>{`distribute`}</a>{``}</li>
          <li parentName="ul">{`Moderate statements in `}<a parentName="li" {...{
              "href": "/Moderation",
              "title": "👹 Moderation"
            }}>{`👹 Moderation`}</a>{``}</li>
          <li parentName="ul">{`See `}<a parentName="li" {...{
              "href": "/Summary-Statistics",
              "title": "Summary Statistics"
            }}>{`Summary Statistics`}</a>{` in `}<a parentName="li" {...{
              "href": "/monitor",
              "title": "monitor"
            }}>{`monitor`}</a>{``}</li>
          <li parentName="ul">{`See a real time `}<a parentName="li" {...{
              "href": "/report",
              "title": "report"
            }}>{`report`}</a>{` of the `}<a parentName="li" {...{
              "href": "/opinion-space",
              "title": "opinion space"
            }}>{`opinion space`}</a>{` in `}<a parentName="li" {...{
              "href": "/report",
              "title": "report"
            }}>{`report`}</a>{``}</li>
          <li parentName="ul">{`Download `}<a parentName="li" {...{
              "href": "/Polis-Conversation-Data",
              "title": "Polis Conversation Data"
            }}>{`Polis Conversation Data`}</a>{` in `}<a parentName="li" {...{
              "href": "/export",
              "title": "export"
            }}>{`export`}</a>{``}</li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      